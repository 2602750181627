exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-app-js": () => import("./../../../src/pages/mobile-app.js" /* webpackChunkName: "component---src-pages-mobile-app-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-and-learning-js": () => import("./../../../src/pages/resources-and-learning.js" /* webpackChunkName: "component---src-pages-resources-and-learning-js" */),
  "component---src-pages-terms-conditions-and-cancellation-policy-js": () => import("./../../../src/pages/terms-conditions-and-cancellation-policy.js" /* webpackChunkName: "component---src-pages-terms-conditions-and-cancellation-policy-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-contact-landing-js": () => import("./../../../src/templates/contactLanding.js" /* webpackChunkName: "component---src-templates-contact-landing-js" */),
  "component---src-templates-country-landing-js": () => import("./../../../src/templates/countryLanding.js" /* webpackChunkName: "component---src-templates-country-landing-js" */),
  "component---src-templates-how-it-works-js": () => import("./../../../src/templates/howItWorks.js" /* webpackChunkName: "component---src-templates-how-it-works-js" */),
  "component---src-templates-post-details-js": () => import("./../../../src/templates/postDetails.js" /* webpackChunkName: "component---src-templates-post-details-js" */),
  "component---src-templates-post-list-view-js": () => import("./../../../src/templates/postListView.js" /* webpackChunkName: "component---src-templates-post-list-view-js" */),
  "component---src-templates-pricing-landing-js": () => import("./../../../src/templates/pricingLanding.js" /* webpackChunkName: "component---src-templates-pricing-landing-js" */),
  "component---src-templates-thankyou-page-js": () => import("./../../../src/templates/thankyouPage.js" /* webpackChunkName: "component---src-templates-thankyou-page-js" */)
}

